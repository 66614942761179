import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Dental Crown | Ceramic Crowns | Cosmetic Dentist Toronto"
     description="If you have damaged or broken teeth, a dental crown may be your only solution. We use all-ceramic and porcelain-fused-to-metal (PFM) options to treat our patients."
      keywords="Dental Crown, Cosmetic Dentist Toronto, Ceramic Crown" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="ceramic-crowns hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>All Ceramic Crowns</h1>
            <h2>Call us today to see if all ceramic crowns are right for you</h2>
          </div>
        </div>
      </div>
    </section>

    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p>
            <h2><a href="/ceramic-crowns">Ceramic Crowns</a></h2></p>
            <h2>What you need to know about tooth-colored ceramic crowns.</h2>
            <p>In the world of dentistry there are two types of "porcelain" crown construction, all-ceramic and porcelain-fused-to-metal (PFM).</p>
            <ul>
              <li>
                <b>PFM's</b> - These crowns are made up of two components. Crown fabrication starts with creating a metal shell (the "substructure") that fits over the trimmed tooth. Porcelain is them fused over this substructure so to give the crown its tooth-like shape and color. (See diagram below.)
              </li>
              <li><b>All-ceramics</b> - The entire thickness of the crown is 100% ceramic.</li>
            </ul>
            <h2>Why this difference matters?</h2>
            <p>Light handling characteristics.</p>
            <p>The lustrous (shiny, glossy, luminous, sparkling, glistening) look of a natural tooth is generated by the way light penetrates into its enamel layer and then is reflected back out.</p>
            <p>The goal of porcelain crown construction is to mimic this effect. And generally speaking, the thicker and more translucent the crown's porcelain, the closer it will match the light handling characteristics (and thus appearance) of a natural tooth.</p>
            <h3>The problem with PFM's.</h3>
            <p>Since the metal substructure of a PFM crown is dark, it must be masked over with a layer of very opaque (chalky-white) porcelain so its tint doesn't show through.</p>
            <p>As a result, only a comparatively thinner layer of translucent porcelain can be placed (see diagram), thus reducing the crown's overall ability to truly mimic the look of a natural tooth.</p>
            <h3>The advantage of all-ceramics.</h3>
            <p>Since all-ceramic crowns aren't burdened with the task of masking a dark metal substructure, a greater portion of their thickness can be composed of comparatively more translucent porcelain. And due to this fact, they tend to create a more lifelike look.</p>
            <h3>Allergic Reactions and Biocompatibility</h3>
            <ul>
              <li>Placing an all-ceramic crown may offer some advantages in regard to biocompatibility.</li>
              <li>The types of ceramics used to make them are typically no more abrasive than dental enamel itself (gold crowns have this advantage too.).</li>
              <li>In comparison, the type of porcelain used to make PFM crowns can be very abrasive to opposing teeth and dental restorations and cause significant wear of them (especially in cases where it hasn't been polished or glazed appropriately).</li>
              <li>While relatively rare, some people are allergic to some of the component metals found in the various types of alloys that can be used to make PFM crowns.</li>
            </ul>
            <h3>The Smile Stylist All Ceramic Crowns</h3>
            <p>While PFM's are still a viable alternative, your Smile Stylist will almost exclusively use all-ceramic or zirconia crowns in the anterior (front) area of the mouth for their amazing aesthetics and durability. Call us today to see if all ceramic crowns are right for you.</p>
            <p><a className="btn btn-red" href="/contact">Book Your Appointment</a></p>
          </div>
          <div className="col-sm-4 wow animated zoomIn text-center" data-wow-delay="0.4s">
            <img src={ require("../images/desktop/services/ceramic-crown.jpg")} alt="Dental Crown" className="img-thumbnail" />
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage